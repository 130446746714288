import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import styles from './accolades.module.css'
import Figma from '../figma/figma'
import { useTranslation } from '../../i18n'
import translations from './accolades.translations'

import circleBack from './circle-back.png'
import bcorp from './bcorp.svg'
import pride from './pride.jpg'

const Accolades = () => {
  const t = useTranslation(translations)

  return (
    <div className={styles.root}>
      <Figma.Layout type='threeCol'>
        <div>
          <div className={styles.circleBackAndBcorpDiv}>
            <img
              loading='lazy'
              className={`${styles.image} ${styles.circleBackAndBcorpImg}`}
              src={bcorp}
              alt={t.bcorp.alt}
            />
          </div>
          <div className={styles.title}>{t.bcorp.title}</div>
          <div className={styles.sub}>{t.bcorp.sub}</div>
          <div className={styles.link}>
            <AnchorWithArrow href='https://www.bcorporation.net/en-us/find-a-b-corp/company/envato'>
              {t.bcorp.readMore}
            </AnchorWithArrow>
          </div>
        </div>
        <div>
          <div className={styles.circleBackAndBcorpDiv}>
            <img
              loading='lazy'
              className={`${styles.image} ${styles.circleBackAndBcorpImg}`}
              src={circleBack}
              alt={t.circleBack.alt}
            />
          </div>
          <div className={styles.title}>{t.circleBack.title}</div>
          <div className={styles.sub}>{t.circleBack.sub}</div>
          <div className={styles.link}>
            <AnchorWithArrow href='https://www.circlebackinitiative.com/'>{t.circleBack.readMore}</AnchorWithArrow>
          </div>
        </div>
        <div>
          <img loading='lazy' className={styles.image} src={pride} alt={t.pride.alt} />
          <div className={styles.title}>{t.pride.title}</div>
          <div className={styles.sub}>{t.pride.sub}</div>
          <div className={styles.link}>
            <AnchorWithArrow href='https://www.prideinclusionprograms.com.au/'>{t.pride.readMore}</AnchorWithArrow>
          </div>
        </div>
      </Figma.Layout>
    </div>
  )
}

export default Accolades
