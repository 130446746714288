export default {
  en: {
    bcorp: {
      alt: 'A certified B Corp',
      title: 'A certified B Corp',
      sub: 'Certified since June 2020',
      readMore: 'Read more'
    },
    circleBack: {
      alt: 'The Circle Back Initiative',
      title: 'The Circle Back Initiative',
      sub: 'Australia',
      readMore: 'Read more'
    },
    pride: {
      alt: 'Pride in diversity',
      title: 'Pride in diversity',
      sub: 'Australia',
      readMore: 'Read more'
    }
  },
  es: {
    bcorp: {
      alt: 'Somos una Empresa B',
      title: 'Somos una Empresa B',
      sub: 'Certificados desde junio de 2020',
      readMore: 'Lee mas'
    },
    circleBack: {
      alt: 'The Circle Back Initiative',
      title: 'The Circle Back Initiative',
      sub: 'Australia',
      readMore: 'Lee mas'
    },
    pride: {
      alt: 'Orgullo en la Diversidad',
      title: 'Orgullo en la Diversidad',
      sub: 'Australia',
      readMore: 'Lee mas'
    }
  }
}
